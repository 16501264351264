import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconButton } from "../icon-button/IconButton";
import { SubSubCaption } from "../typography/SubSubCaption";
import { consolidateStyles as _consolidateStyles } from "../../lib/dom";
import { downloadSvgAsPng } from "../../lib/download-svg";
import { deepGet } from "../../lib/util";
import "./compass.scss";

/**
 * The progressive/conservative left wing/right wing quadrant.
 */
export const Compass = ({
    aspect,
    axesColor,
    axisX,
    axisY,
    combineLabels,
    labelColor,
    svgReady,
    rtl,
    ...props
}) => {
    const svg = useRef();
    const figure = useRef();
    const lastKnownWidth = useRef();
    const [comparativeEntitiesLoaded, setComparativeEntitiesLoaded] =
        useState(false);

    /**
     * Updates the style of the figure.
     * @returns {number} Return value of requestAnimationFrame.
     */
    const updateStyle = useCallback(() => {
        if (!figure.current) {
            return;
        }

        return requestAnimationFrame(() => {
            try {
                const width = figure.current.clientWidth;

                // If SVG is resized, re-render to make sure clipping masks adjust.
                if (
                    lastKnownWidth.current &&
                    lastKnownWidth.current !== width
                ) {
                    figure.current.style.display = "none";

                    requestAnimationFrame(() => {
                        figure.current.style.removeProperty("display");
                    });
                }

                // Housekeeping.
                lastKnownWidth.current = width;
            } catch (e) {}
        });
    }, [figure, lastKnownWidth]);

    /**
     * Redraw the compass on resize.
     */
    useEffect(() => {
        const _svg = svg.current;
        window.addEventListener("resize", updateStyle);
        const timeout = setTimeout(updateStyle);

        if (_svg) {
            _svg.addEventListener("mouseenter", updateStyle);
        }

        return () => {
            window.removeEventListener("resize", updateStyle);
            _svg?.removeEventListener("mouseenter", updateStyle);
            clearTimeout(timeout);
        };
    }, [svg, updateStyle]);

    /**
     * Call the "svgReady" callback.
     * To prevent possible infinite loops, `svgReady` is omitted from dependency array.
     */
    useEffect(() => {
        try {
            svgReady(svg.current);
        } catch (e) {}
    }, [svg]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Sets focus on the comparative entities once loaded.
     */
    useEffect(() => {
        if (comparativeEntitiesLoaded) {
            return;
        }
        const activeElement = svg.current?.querySelector(
            ".CompassParty--active"
        );
        const firstElement = svg.current?.querySelector(".CompassParty");
        const target = activeElement || firstElement;

        if (!target) {
            return;
        }

        requestAnimationFrame(() => target.focus());
        setComparativeEntitiesLoaded(true);
    }, [props.children, comparativeEntitiesLoaded]);

    /**
     * Returns the letter spacing, this should not be set in rtl mode.
     * @return {null|number}
     */
    const getLetterSpacing = () => {
        return rtl ? null : 2;
    };

    const className = classNames({
        Compass: true,
        "Compass--aspect": aspect,
    });

    /**
     * Renders the labels, as configured.
     * @return {JSX.Element}
     */
    const renderLabels = () => {
        if (combineLabels) {
            return renderCombinedLabels();
        }
        return renderAxisLabels();
    };

    /**
     * Renders individual labels next to each axis.
     * @return {JSX.Element}
     */
    const renderAxisLabels = () => {
        return (
            <>
                {/* Progressive. */}
                <text
                    fontFamily="Helvetica, Arial, sans-serif"
                    fontSize="18"
                    letterSpacing={getLetterSpacing()}
                    textAnchor="middle"
                    x="350"
                    y="30"
                    fill="#404040"
                >
                    {axisY.positive_identity}
                </text>

                {/* Conservative. */}
                <text
                    fontFamily="Helvetica, Arial, sans-serif"
                    fontSize="18"
                    letterSpacing={getLetterSpacing()}
                    textAnchor="middle"
                    x="350"
                    y="690"
                    fill="#404040"
                >
                    {axisY.negative_identity}
                </text>

                {/* Left wing. */}
                <text
                    fontFamily="Helvetica, Arial, sans-serif"
                    fontSize="18"
                    letterSpacing={getLetterSpacing()}
                    textAnchor="middle"
                    transform="rotate(-90 20 350)"
                    x="20"
                    y="350"
                    fill="#404040"
                >
                    {axisX.negative_identity}
                </text>

                {/* Right wing. */}
                <text
                    fontFamily="Helvetica, Arial, sans-serif"
                    fontSize="18"
                    letterSpacing={getLetterSpacing()}
                    textAnchor="middle"
                    transform="rotate(90 680 350)"
                    x="680"
                    y="350"
                    fill="#404040"
                >
                    {axisX.positive_identity}
                </text>
            </>
        );
    };
    /**
     * Renders combined labels in each corner.
     * @return {JSX.Element}
     */
    const renderCombinedLabels = () => {
        return (
            <>
                {/* Progressive/left. */}
                <foreignObject x="0%" y="0%" width="100%" height="100%">
                    <body xmlns="http://www.w3.org/1999/xhtml">
                        <SubSubCaption
                            backgroundColor={labelColor ? axesColor : null}
                            color={labelColor || axesColor}
                        >
                            {axisY.positive_identity}{" "}
                            {String(axisX.negative_identity).toLowerCase()}
                        </SubSubCaption>
                    </body>
                </foreignObject>

                {/* Conservative/left. */}
                <foreignObject
                    x="0%"
                    y="100%"
                    width="100%"
                    height="45"
                    transform="translate(0 -45)"
                >
                    <body xmlns="http://www.w3.org/1999/xhtml">
                        <SubSubCaption
                            backgroundColor={labelColor ? axesColor : null}
                            color={labelColor || axesColor}
                        >
                            {axisY.negative_identity}{" "}
                            {String(axisX.negative_identity).toLowerCase()}
                        </SubSubCaption>
                    </body>
                </foreignObject>

                {/* Progressive/right. */}
                <foreignObject x="0%" y="0%" width="100%" height="100%">
                    <body
                        class="rightAlignment"
                        xmlns="http://www.w3.org/1999/xhtml"
                        align="right"
                    >
                        <SubSubCaption
                            align="right"
                            backgroundColor={labelColor ? axesColor : null}
                            color={labelColor || axesColor}
                        >
                            {axisY.positive_identity}{" "}
                            {String(axisX.positive_identity).toLowerCase()}
                        </SubSubCaption>
                    </body>
                </foreignObject>

                {/* Conservative/right. */}
                <foreignObject
                    x="0%"
                    y="100%"
                    width="100%"
                    height="45"
                    transform="translate(0 -45)"
                >
                    <body
                        class="rightAlignment"
                        xmlns="http://www.w3.org/1999/xhtml"
                        align="right"
                    >
                        <SubSubCaption
                            align="right"
                            backgroundColor={labelColor ? axesColor : null}
                            color={labelColor || axesColor}
                        >
                            {axisY.negative_identity}{" "}
                            {String(axisX.positive_identity).toLowerCase()}
                        </SubSubCaption>
                    </body>
                </foreignObject>
            </>
        );
    };

    /**
     * Renders the SVG.
     * @return {JSX.Element}
     */
    const renderSVG = () => (
        <svg
            ref={svg}
            className="Compass__svg"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="100%"
            viewBox="0 0 700 700"
        >
            {renderLabels()}

            <svg
                className="Compass__compass"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width={"80%"}
                height={"80%"}
                x={"10%"}
                y={"10%"}
                {...props}
            >
                {/* Lines. */}
                <line
                    x1="0%"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    stroke={axesColor}
                    strokeWidth="2"
                ></line>
                <line
                    x1="50%"
                    y1="0%"
                    x2="50%"
                    y2="100%"
                    stroke={axesColor}
                    strokeWidth="2"
                ></line>

                {/* Children. */}
                {renderChildren()}
            </svg>
        </svg>
    );

    /**
     * Renders children.
     * @returns {JSX.Element[]}
     */
    const renderChildren = () => {
        const children =
            props.children && props.children.length
                ? props.children
                : [props.children];
        return children.map((child, index) => <g key={index}>{child}</g>);
    };

    return (
        <figure ref={figure} className={className} {...props}>
            <figcaption className="Compass__caption">Landscape</figcaption>
            {renderSVG()}
        </figure>
    );
};

Compass.propTypes = {
    /** Whether to use CSS padding to preserve aspect ratio. */
    aspect: PropTypes.bool,

    /** The color of the axes. */
    axesColor: PropTypes.string,

    /** The configuration of the X axis (negative_identity, positive_identity). */
    axisX: PropTypes.object,

    /** The configuration of the Y axis (negative_identity, positive_identity). */
    axisY: PropTypes.object,

    /** Whether to combine labels. */
    combineLabels: PropTypes.bool,

    /** The color of the labels. */
    labelColor: PropTypes.string,

    /** Whether the selected language requires RTL (right to left) mode. */
    rtl: PropTypes.bool,

    /** Svg ready callback. */
    svgReady: PropTypes.func,
};

Compass.defaultProps = {
    aspect: false,
    axesColor: "#999999",
    axisX: {},
    axisY: {},
    combineLabels: false,
    labelColor: null,
    rtl: false,
    svgReady: () => {},
};

/**
 * Renders the download svg icon button.
 * @param {Tool} tool
 * @param {Element} svg
 * @param [background]
 * @param [color]
 * @param [size]
 * @param [pad]
 * @param [consolidateStyles]
 * @param [tabIndex]
 * @return {JSX.Element|null}
 */
export const renderDownloadCompass = (
    tool,
    svg,
    background = "#3A3F43",
    color = "#FFF",
    size = "normal",
    pad = false,
    consolidateStyles = false,
    tabIndex = 303
) => {
    if (!tool.social_tool_setting) {
        return null;
    }

    /**
     * Downloads svg as png.
     * @param {SVGElement} svg
     */
    const onClick = () => {
        if (consolidateStyles) {
            // Consolidate styles.
            _consolidateStyles(
                svg,
                ".Compass, .CompassParty, CompassUser, .SubSubCaption, svg, foreignObject, body"
            );
        }

        // Wait for nested images to be downloaded.
        const startTimeStamp = parseInt(Date.now() / 1000);

        const deferedDownload = () => {
            // Wait max 3s for images to be downloaded and 'inlined' to base64.
            if (
                svg.innerHTML.match("href=.http") &&
                parseInt(Date.now() / 1000) - startTimeStamp < 3
            ) {
                // Relax, take your time, Infinity - Guru Josh Project.
                setTimeout(deferedDownload, 300);
                return;
            }

            downloadSvgAsPng(svg, "Kieskompas.png", 1400, 1500, "#FFF");
        };

        setTimeout(deferedDownload);
    };

    return (
        <IconButton
            icon="external-link"
            background={background}
            color={color}
            label={deepGet(tool, "social_tool_setting.download", "Download")}
            pad={pad}
            size={size}
            onClick={onClick}
            tabIndex={tabIndex}
        />
    );
};
